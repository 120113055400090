<template>
  <div class="payment bsbb">
    <van-nav-bar
      title="支付方式"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    />
    <div class="df fdc paylist bsbb p20">
      <van-radio-group v-model="checked" @change="change">
        <div class="df aic jcsb payitem mb40" >
          <div class="df aic jcc">
            <p class="font-size-24 fw7">USDT</p>
          </div>
          <van-radio
            checked-color="green"
            icon-size="20px"
            name="1"
          ></van-radio>
        </div>
        <div class="df aic jcsb payitem mb40" @change="change">
          <div class="df aic jcc">

            <p class="font-size-24 fw7">ROC</p>
          </div>
          <van-radio
            checked-color="green"
            icon-size="20px"
            name="3"
          ></van-radio>
        </div>
      <!--  <div class="df aic jcsb payitem mb40" @change="change">
          <div class="df aic jcc">
            <p class="font-size-24 fw7">消费券</p>
          </div>
          <van-radio
            checked-color="green"
            icon-size="20px"
            name="4"
          ></van-radio>
        </div>
        <div class="df aic jcsb payitem mb40" @change="change">
          <div class="df aic jcc">
            <p class="font-size-24 fw7">充值积分+消费积分</p>
          </div>
          <van-radio
            checked-color="green"
            icon-size="20px"
            name="5"
          ></van-radio>
        </div>
        <div class="df aic jcsb payitem mb40" >
          <div class="df aic jcc">
            <p class="font-size-24 fw7">绿色积分+消费积分</p>
          </div>
          <van-radio
            checked-color="green"
            icon-size="20px"
            name="6"
          ></van-radio>
        </div> -->
      </van-radio-group>
    </div>
   <!-- <div class="addCard" @click="bindCard">
      <img src="../../assets/images/xg/addCard.png" />
    </div> -->
    <div class="foot">
      <van-button type="success" size="large" @click="payments"
        >确认支付方式</van-button
      >
    </div>
  </div>
</template>

<script>
import { orderPay, createOrderReq } from "../../utils/api";
import { Toast } from "vant";
export default {
  data() {
    return {
      checked1: true,
      checked2: true,
      checked3: true,
      order_no: this.$route.query.id,
      checked: '',
      orderWay: '1',
      payment_method: '',
      order_info: JSON.parse(localStorage.getItem("orderList")),
      ids: [],
    };
  },
  computed: {
    payState: {
      get() {
        if (this.orderWay == 1) {
          return (this.payment_method = "1");
        } else if (this.orderWay == 2) {
          return (this.payment_method = "3");
        } else if (this.orderWay == 3) {
          return (this.payment_method = "4");
        } else if (this.orderWay == 4) {
          return (this.payment_method = "5");
        } else if (this.orderWay == 5) {
          return (this.payment_method = "6");
        }
      },
    },
  },
  methods: {
    getTotalPrice() {
      this.order_info.reduce((total, v) => {
        return total + v.trade_price * v.buy_quantity;
      }, 0);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    change(e) {
      console.log(e);
      this.orderWay = e;
    },
    payment() {
      orderPay({ order_no: this.order_no }).then((res) => {
        if (code === 200) {
          Toast.success(res.message);
        } else {
          Toast.fail(res.message);
        }
      });
      setTimeout(() => {
        this.$router.push("/home");
      }, 2000);
    },
    payments() {
      console.log(this.orderWay, 'ssd');
      localStorage.setItem("wayts", JSON.stringify(this.orderWay));
      this.$router.go(-1)
    },
    // 绑定银行卡
    bindCard() {
      this.$router.push("/bindCard");
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;

  .paylist {
    margin-top: 50px;

    .payitem {
      img {
        width: 84px;
        height: 83.99px;
      }
    }
  }

  .addCard {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 638px;
      height: 133px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(242, 242, 242, 1);
      border: 2px solid rgba(151, 151, 151, 0.5);
    }
  }

  .cards {
    img {
      width: 638px;
      height: 409px;
      opacity: 1;
    }
  }

  .van-button {
    padding: 30px;
  }

  .foot {
    .van-button {
      padding: 30px;
      height: 100px;
      border-radius: 20px;
    }
  }
}
</style>
